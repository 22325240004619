import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"


const Badge = ({ data }) => {
  const badge = data.pluralsightJson
  return (
    <Layout>
      <div>
        <h1>{badge.title}</h1>
        <div>{badge.score}</div>
        <div>{Math.floor(badge.percentile)}%</div>
        <div>{badge.dateCompleted}</div>
        <div>{badge.level}</div>
        <GatsbyImage
          image={badge.localImage.childImageSharp.gatsbyImageData}
          alt={badge.title}
          style={{ float: "left", marginRight: "1rem", width: 150 }} />
      </div>
    </Layout>
  );
}

export const query = graphql`query ($badge: String!) {
  pluralsightJson(id: {eq: $badge}) {
    percentile
    title
    dateCompleted(fromNow: true)
    level
    thumbnailUrl
    localImage {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    score
  }
}
`

export default Badge
